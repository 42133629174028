import React from 'react'
import { Box, useTheme } from '@mui/material'

export const Logo: React.FC<BrandLogo> = ({ color = 'primary', ...props }) => {
  const { palette } = useTheme()
  let fill

  //@ts-ignore
  if (color) fill = palette[color].main

  return (
    <Box width={160} {...props} sx={{ fill }}>
      <svg viewBox="0 0 385 97" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M143.51,58.29c-2.67,0-5.35,0-8.16,0,0,.57-.06,1.07-.06,1.57,0,11.68,0,24.65,0,36.33l-1.93,2.24H128l-2.12-2.18v-38H115l-.06,38.14-2,2.05h-5.3l-2.06-1.93,0-46.18a3.74,3.74,0,0,1,3.82-3.77h33.95a11.4,11.4,0,0,1,11.07,7.95,20.2,20.2,0,0,1,1.29,6.67c.09,8.52.13,17,.18,25.55,0,2.72,0,9.66,0,9.66l-2.08,2h-5.32l-2.24-2.08,0-30.49a30.19,30.19,0,0,0-.23-4.32C145.62,59.9,145,58.3,143.51,58.29Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M60,56.06V67.71l32.48.1,2.27,2.31-.09,5.06L92.8,77.06h0q-15.55.2-31.2.21H60c0,.48-.08.79-.08,1.11,0,1.57,0,2.61,0,3.37,0,1.15.06,1.73.06,2.12,0,1.17,0,2.12-.08,2.72a2.39,2.39,0,0,0,.34.74A2.7,2.7,0,0,0,61,88a3.89,3.89,0,0,0,1.08.61,3.74,3.74,0,0,0,1.23.22q1.51.08,3,.11L92.5,89l2,2.07c0,.22.06.55.09,1s.08,1.05.08,2.1c0,.68,0,1.5-.09,2.42l-2.08,1.74-.33.06-26.64.07-3.86-.11a13.38,13.38,0,0,1-4.58-.86,12.14,12.14,0,0,1-5.34-3.75,11.88,11.88,0,0,1-2.41-5.31V86l-.07-37.42,2.34-2.13,40.88.18,2.13,2q0,.55,0,1.1,0,2.2,0,4.38l-2.14,1.79q-15.43.18-30.94.19Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M295.83,67.66l.24-11.83h-1.46c-9.76,0-19.51,0-29.27,0a4.37,4.37,0,0,1-2-.45,4.88,4.88,0,0,1-2.1-2.26l0-4.25a4.15,4.15,0,0,1,.16-.41,4.88,4.88,0,0,1,2.13-2.17l40.72-.11a3.32,3.32,0,0,1,.89.58,4.73,4.73,0,0,1,.61.72,4.64,4.64,0,0,1,.67,1,3.24,3.24,0,0,1,.22,1.24q0,18.29,0,36.59a11.63,11.63,0,0,1-2.19,7.08A11.31,11.31,0,0,1,298,97.55a21.48,21.48,0,0,1-5.68.79c-5-.12-14.71-.06-27.71,0a4.06,4.06,0,0,1-1.08-.26,4.3,4.3,0,0,1-1.95-1.58l-.21-.95-.16-3.89.54-1.41a3.3,3.3,0,0,1,2.4-1.43L277,88.77l12.94-.05a11.24,11.24,0,0,0,3.31-.32,3.74,3.74,0,0,0,1.95-.89,3.13,3.13,0,0,0,.68-2.37V77.09L294.56,77,265,76.78a3,3,0,0,1-1.64-.06,3.28,3.28,0,0,1-1.93-1.94V69.41l2-1.84Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M179.33,96.26a26.54,26.54,0,0,1-12.2-13c-.51-1.18-5.52-13.31,1.78-24.77,7.43-11.67,20.89-12.08,22.33-12.1,1,0,15.57,0,23.27,12.39a26.73,26.73,0,0,1-1.13,29.65C205.21,99.56,189.84,101.47,179.33,96.26Zm11.73-40.72c-9.18.28-16,8.68-15.86,17.45.16,8.62,7.07,16.62,16,16.8,9.32.18,16.78-8.18,16.7-17.26S200.39,55.26,191.06,55.54Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M368.76,48l.87-.81L375.3,47V45.9l0-8.2,0-1.65L376.52,35l8.23.12,1.05,1,.1,11,1.56.09a67.52,67.52,0,0,0,7.11,0l1.32,1.34v7.81a11.27,11.27,0,0,1-1.1.82l-.25.16-8.69,0-.09,1.37V82l.12,1.68a4.56,4.56,0,0,0,1.36,3.54,4.06,4.06,0,0,0,2.07.78,18.23,18.23,0,0,0,5.78,0h0l1.32,1.08-.14,8.53a26.13,26.13,0,0,1-8.78.65c-.39,0-.95-.1-1.62-.21-2.11-.37-5.17-.9-7.38-3.06a11.06,11.06,0,0,1-2.78-5.75,16.6,16.6,0,0,1-.41-5.57l-.25-26.46-5.53,0-.67-.83Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M251.46,48.39l.15,7.8-1.09,1.08-8.81,0,0,26.21a8.11,8.11,0,0,0,.29,1.95,3.53,3.53,0,0,0,.64,1.49,3.44,3.44,0,0,0,2.06.92,13,13,0,0,0,3.12.18c.77,0,1.77,0,2.94-.09l1.3,1.24v8.45l-3,.61-6.94,0a13.65,13.65,0,0,1-4.53-1.06,10.12,10.12,0,0,1-2.6-1.48,10.76,10.76,0,0,1-3.21-4.88,14.92,14.92,0,0,1-.87-3.44q0-2.16,0-4.31,0-12.88,0-25.75h-5.49l-1.26-1.06,0-8.12,1.1-1.06.73,0h3.76l1.1,0,0-10.92,1.33-1h8.22l1.1,1.1.13,11,7.39,0,1.34,0Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M22.91,48l0,3.78a14.21,14.21,0,0,1,4.35-3.73,15.09,15.09,0,0,1,3.1-1.24,16.49,16.49,0,0,1,2.21-.45,22.74,22.74,0,0,1,4-.22l2.65.22.13,10.18-1.46.69c-1.41-.06-2.58,0-3.44,0a17.13,17.13,0,0,0-4.38.51,9.89,9.89,0,0,0-3,1.28,9,9,0,0,0-3,3.23,10.65,10.65,0,0,0-1,4.32,16.87,16.87,0,0,0-.05,2.4c.11,2.21.09,10.94-.09,27.95l-1.12,1.39-.35.09-8.94,0L11.41,97.1l-.13-.49V47.85l1.24-1,9-.09Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M358.44,96.86A2.49,2.49,0,0,1,356,98.1c-2-.09-4,0-6,0a2,2,0,0,1-2.24-2.25q0-17.45,0-34.9,0-5.88,0-11.75A1.92,1.92,0,0,1,349.84,47c2.24,0,4.48,0,6.72,0A1.83,1.83,0,0,1,358.47,49q0,23.75,0,47.5C358.47,96.61,358.45,96.73,358.44,96.86Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M191,3.05c8.25.08,15.46,1.61,22.22,5.08a40.41,40.41,0,0,1,7.49,5,2.75,2.75,0,0,1-.36,4.53,5.07,5.07,0,0,1-5.74-.26c-1.33-1-2.61-2-4-2.87a37.86,37.86,0,0,0-35-1.56,33.55,33.55,0,0,0-6.75,4.36,6.63,6.63,0,0,1-3.12,1.72,4.62,4.62,0,0,1-4.1-1.23,2.5,2.5,0,0,1-.11-3.68,15.65,15.65,0,0,1,1.66-1.39,41,41,0,0,1,10.18-6.1,45.9,45.9,0,0,1,13.68-3.33C188.64,3.19,190.2,3.11,191,3.05Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M191.68,14.4A27.37,27.37,0,0,1,210,21a2.89,2.89,0,0,1-.3,4.85,5.44,5.44,0,0,1-6.29-.23c-.52-.38-1-.78-1.53-1.16A17.5,17.5,0,0,0,181,24.9c-.69.54-1.38,1.09-2.11,1.58a5.46,5.46,0,0,1-6.27-.33,2.79,2.79,0,0,1-.05-4.48A29.23,29.23,0,0,1,182,16,30.3,30.3,0,0,1,191.68,14.4Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M334.59,90.56A27,27,0,0,1,334,93.4a7.29,7.29,0,0,1-7.55,5.13,7.42,7.42,0,0,1-6.84-6.05,8.81,8.81,0,0,1,1-6.44A6.94,6.94,0,0,1,327,82.49a7.07,7.07,0,0,1,6.32,3.58A10.07,10.07,0,0,1,334.59,90.56Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M191.4,26.82A8.11,8.11,0,0,1,191.1,43a7.9,7.9,0,0,1-7.67-8.44A7.9,7.9,0,0,1,191.4,26.82Z"
          transform="translate(-11.27 -3.05)"
        />
        <path
          d="M346.17,33.38c.11-3.72,2.34-6.47,5.79-7.09a6.8,6.8,0,0,1,7.9,5.15A8.28,8.28,0,0,1,359.7,36a6.87,6.87,0,0,1-13.18-.43C346.34,34.88,346.28,34.12,346.17,33.38Z"
          transform="translate(-11.27 -3.05)"
        />
      </svg>
    </Box>
  )
}
